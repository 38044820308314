.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-tab-label {
  font-size: 15px !important;
}

.btn-navigation {
  padding: 2px 2px 2px 3px !important;
  border-radius: 9px !important;
  border-color: #CED3DE !important;
}

.btn-navigation-icon {
  width: 20px !important;
  height: 20px !important;
  color: #616161 !important;
}

.rotate-icon {
  width: 10px !important;
  height: 10px !important;
  margin-right: 18px;
  stroke: #000 !important;
  stroke-width: 3px !important;
}

.btn-toggle-team {
  gap: 8px !important;
  color: #808080 !important;
  background-color: #ECECEC !important;
  &.Mui-selected {
    color: #FFF !important;
    background-color: #007CFF !important;
    &:hover { background-color: #007CFF !important }
  };
  @media (hover: none) {
    &:hover { background-color: #ECECEC !important }
  };
}

.btn-toggle-private {
  gap: 8px !important;
  color: #808080 !important;
  background-color: #ECECEC !important;
  &.Mui-selected {
    color: #FFF !important;
    background-color: #00D4EA !important;
    &:hover { background-color: #00D4EA !important }
  };
  @media (hover: none) {
    &:hover { background-color: #ECECEC !important }
  };
}